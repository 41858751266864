@keyframes pointFade {
  0%,
  19.999%,
  100% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
}

@keyframes arrow-pulse {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  30% {
    transform: translate(0, 300%);
    opacity: 1;
  }
  70% {
    transform: translate(0, 700%);
    opacity: 1;
  }
  100% {
    transform: translate(0, 1000%);
    opacity: 0;
  }
}