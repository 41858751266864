.k-treeview .k-in.k-state-selected {
  border-color: rgb(245, 40, 40) !important;
  color: #000000;
  background-color: #fff;
}

.k-treeview .k-in {
  border: 1px solid rgba(47, 72, 88, 0.15);
  border-radius: 4px;
  padding: 0.2rem 0.2rem;
}
